const checkIfUserIsLogged = () => {
    $('#cart-data').data('userIsLogged') === true ? $('#user-login').hide() : $('#user-profile, #user-orders, #user-logout, #user-tickets').hide();
}

const setProperFlagPath = () => {
    const languageData = $('#language-data');
    const flagPath = languageData.data('locale') === 'ro' ? languageData.data('enPath') : languageData.data('roPath');

    $('#header-item-language > a').attr('href', flagPath);
}

checkIfUserIsLogged();
setProperFlagPath();